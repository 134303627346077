import 'focus-visible'
import React from 'react'
import PropTypes from 'prop-types'
import Header from '../../components/site-header'
import Footer from '../../components/site-footer'
import LayoutBase from '../LayoutBase'
import classNames from 'classnames'
import './layout.scss'


const Layout = ({
    children,
    className,
    hideFooter = false,
    footerProps = {},
    headerProps = {},
    theme = 'dark',
    ...props
}) => (
    <LayoutBase
        className={classNames('Layout', className)}
        theme={theme}
        {...props}
    >
        <Header {...headerProps} />
        {children}
        {!hideFooter && <Footer {...footerProps} />}
    </LayoutBase>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
