import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/default/layout'
import SEO from '../../components/seo'
import Img from 'gatsby-image'
import './contact.scss'

export default ({
    data: {
        page,
        wordpressAcfOptions: {
            options: {
                contact_information,
                phone_number,
                email,
                linkedin,
                facebook,
                instagram,
            },
        },
    },
}) => (
    <Layout hideFooter={true}>
        <SEO
            yoast={page.yoast}
            title={page.title}
            description={page.content}
            dateModified={page.modified}
            datePublished={page.date}
        />
        <main className="Contact mb-yd">
            <div className="container-fluid px-yd mt-yd">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <h2 className="type-label text-secondary">{`General inquiries`}</h2>
                        {/* <a
                            className="type-subtitle"
                            href={`tel:${phone_number}`}
                        >
                            {phone_number}
                        </a>
                        <br /> */}
                        <a className="type-subtitle" href={`mailto:${email}`}>
                            {email}
                        </a>
                    </div>
                    {/* <div className="col-12 col-lg-4">
                        <h2 className="type-label text-secondary">{`Swing by the office`}</h2>
                        <div
                            className="type-subtitle"
                            dangerouslySetInnerHTML={{
                                __html: contact_information.replace(
                                    /\n/g,
                                    '<br />'
                                ),
                            }}
                        />
                    </div> */}
                    <div className="col-12 col-lg-6">
                        <h2 className="type-label text-secondary">{`Follow along`}</h2>
                        <ul className="list-unstyled">
                            <li>
                                <a
                                    href={linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="type-subtitle"
                                >
                                    LinkedIn
                                </a>
                            </li>
                            <li>
                                <a
                                    href={facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="type-subtitle"
                                >
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    href={instagram}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="type-subtitle"
                                >
                                    Instagram
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-4">
                        <Img
                            className="featured-image"
                            fluid={
                                page.featuredMedia.localFile.childImageSharp
                                    .fluid
                            }
                        />
                    </div>
                </div>
            </div>
        </main>
    </Layout>
)

export const pageQuery = graphql`
    query($id: String!) {
        page: wordpressPage(id: { eq: $id }) {
            title
            content
            ...YoastPage
            featuredMedia: featured_media {
                localFile {
                    childImageSharp {
                        fluid (quality:95) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        wordpressAcfOptions {
            options {
                contact_information
                phone_number
                email
                linkedin
                facebook
                instagram
            }
        }
    }
`
